import $ from "jquery";

const ajaxCall = (url, method="GET", json={}, cb) => {
	$.ajax({
		url: url,
		method: method,
		data: json,
		success: response => {
			if (typeof cb === "function")
				cb(response);
		},
		error: err => {
			if (err.responseJSON && err.responseJSON.reload === true)
				window.location.reload();
			else if (typeof cb === "function")
				cb({ status: "error", data: {} });
		},
	});
};
const ajaxUpload = (url, formData, cb) => {
	$.ajax({
		url: url,
		method: "POST",
		data: formData,
		processData: false,
		contentType: false,
		success: response => {
			if (typeof cb === "function")
				cb(response);
		},
		error: err => {
			console.warn(err.responseText);

			if (err.responseJSON && err.responseJSON.reload === true)
				window.location.reload();
			else if (typeof cb === "function")
				cb({ status: "error", data: {} });
		},
	});
};


const ajaxCall2 = (url, method="GET", json={}, cb) => {
	$.ajax({
		url: url,
		method: method,
		data: json,
		success: response => {
			if (typeof cb === "function")
				cb(response);
		},
		error: err => {
			if (err.responseJSON && err.responseJSON.reload === true)
				window.location.reload();
			else if (typeof cb === "function")
				cb({ status: "error", data: {} });
		},
	});
};

export {
	ajaxCall,
	ajaxUpload,
	ajaxCall2
};