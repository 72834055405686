// class Node {
//     constructor(data, next = null) {
//         this.data = data;
//         this.next = next
//     }
// }

// class LinkedList {
//     constructor() {
//         this.head = null;
//         this.size = null;
//     }

//     insertNode(data) {
//         if(!this.head) {
//             
//             this.head = new Node(data, this.head)
//             this.size++;
//             return;
//         }
        
//         let newNode = new Node(data)
//         let temp = this.head;
//         
//         if(!temp.next) {
//             temp.next = newNode;
//             newNode.next = this.head
//         }
//         else {
//             do {
//                 temp = temp.next
//             }while(temp.next != this.head)
            
//                 temp.next = newNode;
//             newNode.next = this.head
//             this.size++;
            
//         }
//     }

//     loopNode() {
//         
//         this.temp = this.head
//         
//         while(this.temp) {
//             
//             const currentSlide = this.temp.data;
//             if(currentSlide.getAttribute('data-show') === 'true') {
//                 
//                 const currentImg = document.querySelector(`[${img}=${currentSlide.getAttribute(content)}]`)
//                 currentSlide.setAttribute('data-show','false')
//                 currentImg.setAttribute('data-show','false')
//                 const nextSlide = this.next;
//                 const nextImg = document.querySelector(`[${img}=${currentSlide.getAttribute(content)}]`)
//                 nextSlide.setAttribute('data-show','true')
//                 nextImg.setAttribute('data-show','true')
//             }
//                 this.temp = this.temp.next;
            
//         }
//     }

//     printData() {
//         // 
//         let temp = this.head;
//         
//         // while(temp) {
//         //     
//         //     temp = temp.next;
//         // }
//     }
// }

const ourProducts = ({ modifier, toggle, content, img, navigation }) => {
    let isHovered = false;

    const products = [...document.querySelectorAll(`[${content}]`)]
    const images = [...document.querySelectorAll(`[${img}]`)]
    const navigate= [...document.querySelectorAll(`[${navigation}]`)]
    

    // const list = new LinkedList()

    let i = 0;

    function navigateSlide(slide) {
        slide.setAttribute(toggle, 'true')
        const p = slide.querySelector('p')
        // modifier && (p.style.height = (p.scrollHeight + 25) + "px")
        const imgNext = document.querySelector(`[${img}=${slide.getAttribute(content)}]`)
        imgNext.setAttribute(toggle, 'true')
    }


    function changeSlider(nav = null) {
        // console.log('nav')
        if (products[i].getAttribute(toggle) === 'true') {
            // console.log('change slider called inside if condition', products[i]);
            products[i].setAttribute(toggle, 'false')
            const p = products[i].querySelector('p')
            // modifier && (p.style.height = 0)
            const imageSlide = document.querySelector(`[${img}= ${products[i].getAttribute(content)}]`)
            imageSlide.setAttribute(toggle, 'false')
            if ((i === products.length - 1)) {
                if(nav && nav.getAttribute('data-product-navigate') === 'down') {
                    i = 0
                    navigateSlide(products[i])
                }
                else if(nav && nav.getAttribute('data-product-navigate') === 'up') {
                    i = i - 1
                    navigateSlide(products[i])
                }
                else {
                    i = 0;
                    navigateSlide(products[i])
                }
                
            }
            else {
                if(nav && nav.getAttribute('data-product-navigate') === 'down') {
                    navigateSlide(products[i].nextElementSibling)
                    i++;
                }
                else if(nav && nav.getAttribute('data-product-navigate') === 'up') {
                    if(i === 0) {
                        i = i + products.length - 1
                        navigateSlide(products[i])
                    }
                    else {
                        i = i - 1
                        navigateSlide(products[i])
                    }
                    
                }
                else {
                    navigateSlide(products[i].nextElementSibling)
                    i++
                }
                
            }
        }
    }


function navigateTrigger(interval) {
    if (navigate.length) {
        navigate.forEach(element => {
            element.addEventListener('click', function (e) {
                clearInterval(interval)
                changeSlider(e.target)
                // loopSlider()
            })
        })
    }
}

function loopSlider() {
    // console.log("chec")
    const myInterval = setInterval(function () {
    
        function mouseEvent(e) {
            e.type === "mouseover" ? (isHovered = true) : (isHovered = false)
        }
    
        for (let i = 0; i < products.length; i++) {
            products[i].addEventListener('mouseover', mouseEvent)
            products[i].addEventListener('mouseout', mouseEvent)
            images[i].addEventListener('mouseover', mouseEvent)
            images[i].addEventListener('mouseout', mouseEvent)
        }
        
        !isHovered && changeSlider()
        
    }, 2000)
    return myInterval
}
const interval = loopSlider()
navigateTrigger(interval)

}


    // products.forEach(element => {
    //     list.insertNode(element)
    // })

    // list.printData()
    // setTimeout(function() {
    //     setInterval(function() {
    //         list.loopNode()
    //     }, 1000)
    // },5000)

// }

export default ourProducts