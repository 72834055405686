// styles
import "../themes/common.scss";
import "../themes/css/pages/home.scss";
//js
import "../themes/js/common.js";
import "../themes/js/pages/home.js";

import { initmanage } from "../javascripts/component/manage.js";

initmanage();

