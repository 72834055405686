class Intersection {
    constructor({section}) {
        this.section = section
        this.instance = document.querySelector(`[${section}]`)
        console.log(section);
    }

    setIntersection(triggerFunc) {
        const observer = new IntersectionObserver(entries => {
            console.log('entries', entries)
            entries.forEach(entry => {
                entry.isIntersecting && triggerFunc()
            })
        },{threshold: 0.5})

        
            observer.observe(this.instance)
        
    }
}
export default Intersection