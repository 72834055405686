import { swiperImport } from "../../../vendor/swiper/js/swiper.js"

const customerSay = () => {
    console.log('called')
    const swiper = swiperImport()
    const swiperContainer = new swiper('.js-customer',{
        createElements: true,   
        slidesPerView: 1,
        autoplay: {
            delay: 3000
        },
        speed: 1000,
        grabCursor: true,
        keyboard: true,
        loop: true,
        pagination: true,
        parallax: true,
        slideToClickedSlide: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 8,
            },

            420: {
                slidesPerView: 1.5,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            992:{
                slidesPerView: 2.5,
                spaceBetween: 50,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 50,
            }
        }
       
    })
    console.log(swiperContainer)
}
export default customerSay

// const swiper = new swiper('.js-partners')
// console.log(swiper)