function tabActions(e, tab) {
    //To stop event bubling to the parent tabs in case the tab is nested
    e.stopPropagation();
    if (e.target.hasAttribute('data-tab')) {
      let currentActiveTab = [...tab.children].find(
        (element) => element.getAttribute('data-tab-active') === 'true'
      );
  
      currentActiveTab.setAttribute('data-tab-active', 'false');
      document
        .querySelector(
          `[data-tab-content=${currentActiveTab.getAttribute('data-tab')}]`
        )
        .setAttribute('data-tab-content-active', 'false');
      e.target.setAttribute('data-tab-active', 'true');
      document
        .querySelector(`[data-tab-content=${e.target.getAttribute('data-tab')}]`)
        .setAttribute('data-tab-content-active', 'true');
    }
  }
  const tabs = () => {
    // console.log("hiii")
    const tabGroup = [...document.querySelectorAll('[data-tab-group]')];
  
    tabGroup.forEach((element) => {
      element.children[0].addEventListener('click', function (e) {
        tabActions(e, this);
      });
    });
  };

  export default tabs;
  