import TomSelect from "../../vendor/tomselect/js/tomselect.js";

const talkExpert =() =>{
    // console.log("Check Talk Expert")
//    document.addEventListener("DOMContentLoaded", function() {
    const select = document.querySelector("#claim-bonus");
    if (select) {
        document.querySelector("#supportPlanForm").addEventListener('reset', function() {
            const clearEvent = new Event('formReset');
            document.dispatchEvent(clearEvent);
        });
        let tomSelect = new TomSelect(select,{controlInput: "",allowEmptyOption: true,});
        // tomSelect.settings.placeholder = "New placeholder";
        // tomSelect.inputState();
         // Listen for the custom clear event dispatched from the other file
        document.addEventListener('formReset', function() {
            // Clear the value in the Tom Select instance
            tomSelect.destroy()
            tomSelect = new TomSelect(select,{controlInput: "",allowEmptyOption: true,});
        });
    }
// });

}
export default talkExpert