import Intersection from "../../utils/intersectionObserver/intersection.js";
// import header from "../components/header.js";
import talkExpert from "../components/talk-expert.js";   
import customerSay from "./Home/customer-say.js";
import module from "./Home/fdcalculator.js";
import ourProducts from "./Home/our-products.js";
import popularPlans from "./Home/popular-plans.js";
import animate from "../../utils/animateText/animateText.js";
animate()
// setTimeout(function(e) {
    const intersection = new Intersection( {
        section: 'data-section',
    })
    intersection.setIntersection(() => {
        
        ourProducts({modifier: 'height', toggle: 'data-show', content: 'data-product',img: 'data-product-img',})
        // navigation: 'data-product-navigate'
    })
    // header()
    module.fdCalculator()
    module.rangeSlider()
popularPlans()
    customerSay()
    talkExpert()
// },2000)




